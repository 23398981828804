import React from 'react';
import './hero.scss';

const Hero = () => {

    return(
        <section class="hero">
            <div class="hero_image"></div>
            <div class="hero_container">
                <div class="hero_container_plate">
                    <h3 class="hero_container_plate_title">KakaBar</h3>
                    <p class="hero_container_plate_subtitle">Hair & Nail Salon</p>
                    <p class="hero_container_plate_description">At KakaBar, we are determined to make you your best self.</p>
                    <a class="hero_container_plate_call" href="tel:5513130004">
                        <button class="hero_container_plate_call_button">
                            <b>CALL TO MAKE AN APPOINTMENT</b>
                        </button>
                    </a>
                    <a class="hero_container_plate_phone" href="tel:5513130004">
                        <p>Call us at: 551-313-0004</p>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Hero;