import React from 'react';
import './staff.scss';

const Staff = () => {
    return(
        <section class="staff" id="staff">
            <div class="staff_container">

                <div class="staff_container_title">
                    OUR STAFF
                </div>

                <div class="staff_container_names">
                    <div class="staff_container_names_one">
                        <div class="staff_container_names_one_image"></div>
                        <div class="staff_container_names_one_name">Rachel</div>
                    </div>
                    <div class="staff_container_names_two">
                        <div class="staff_container_names_two_image"></div>
                        <div class="staff_container_names_two_name">Nana</div>
                    </div>
                    <div class="staff_container_names_three">
                        <div class="staff_container_names_three_image"></div>
                        <div class="staff_container_names_three_name">Bailey</div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Staff