import React from 'react';
import './footer.scss';
import img1 from '../assets/footer1.png';
import img2 from '../assets/footer2.png';
import img3 from '../assets/footer3.png';

const Footer = () => {
  const images = [
    img1,
    img2,
    img3
  ]

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  }

  return (
    <section class="footer">
      <div class="footer_container">

        <div class="footer_container_title">
          <h1>CONTACT US</h1>
        </div>

        <div class="footer_container_content">

          <div class="footer_container_content_hours">
            <h3 class="footer_container_content_hours_title">BUSINESS HOURS:</h3>
            <div class="footer_container_content_hours_times">
              <p class="footer_container_content_hours_times_days">
                Monday:<br/>
                Tuesday:<br/>
                Wednesday:<br/>
                Thursday:<br/>
                Friday:<br/>
                Saturday:<br/>
                Sunday:<br/>
              </p>
              <p>
                9:30 AM - 7 PM<br/>
                9:30 AM - 7 PM<br/>
                9:30 AM - 7 PM<br/>
                9:30 AM - 7 PM<br/>
                9:30 AM - 7 PM<br/>
                9:30 AM - 7 PM<br/>
                Closed
              </p>
            </div>
          </div>

          <a class="footer_container_content_phone" href="tel:5513130004">
            <div class="footer_container_content_phone_div">
              <img class="footer_container_content_phone_div_image"src={images[0]} alt="phone"/>
              <p>551-313-0004<br/>516-998-6192</p>
            </div>
          </a>

          <div 
            class="footer_container_content_address"
            onClick={() => openInNewTab("https://www.google.com/maps/place/110+Broad+Ave,+Palisades+Park,+NJ+07650/@40.8444143,-74.0027027,17z/data=!3m1!4b1!4m6!3m5!1s0x89c2f70e7ba8dbbb:0xbbe44dd621ec817b!8m2!3d40.8444143!4d-74.0001278!16s%2Fg%2F11b8v6yz85?entry=ttu")}
          >
            <img class="footer_container_content_address_image"src={images[1]} alt="address"/>
            <p>110 Broad Ave. Suite 1 Palisades Park NJ 07650</p>
          </div>

          <a class="footer_container_content_email" href="mailto:kakabarinc@gmail.com">
            <div class="footer_container_content_email_div">
              <img class="footer_container_content_email_div_image"src={images[2]} alt="email"/>
              <p>kakabarinc@gmail.com</p>
            </div>
          </a>

        </div>

      </div>
    </section>
  )
}

export default Footer