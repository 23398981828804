import React from 'react';
import './header.scss';
import img1 from '../assets/footer1.png';
import img2 from '../assets/footer2.png';
import img3 from '../assets/kakabar-logo.png';

const Header = () => {
    const images = [
        img1,
        img2,
        img3
    ]

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    }

    return (
        <section class="header" id="home">
            <div class="header_container">
                <div class="header_container_top">
                    <a class="header_container_top_logo" href="#home">
                        <div class="header_container_top_logo_div">
                            <img class="header_container_top_logo_div_image" alt="logo" src={images[2]} />
                        </div>
                    </a>
                    <div class="header_container_buttons">
                        <a class="header_container_buttons_carousel" href="#carousel">Our Works</a>
                        <a class="header_container_buttons_staff" href="#staff">The Staff</a>
                        <a class="header_container_buttons_services" href="#services">Services</a>
                    </div>
                    <div class="header_container_top_info">
                        <a class="header_container_top_info_phone" href="tel:5513130004">
                            <div class="header_container_top_info_phone_div">
                                <img class="header_container_top_info_phone_div_image" alt="phone" src={images[0]} />
                                <p>551-313-0004<br />516-998-6192</p>
                            </div>
                        </a>

                        <div class="header_container_top_info_address"
                            onClick={() => openInNewTab("https://www.google.com/maps/place/110+Broad+Ave,+Palisades+Park,+NJ+07650/@40.8444143,-74.0027027,17z/data=!3m1!4b1!4m6!3m5!1s0x89c2f70e7ba8dbbb:0xbbe44dd621ec817b!8m2!3d40.8444143!4d-74.0001278!16s%2Fg%2F11b8v6yz85?entry=ttu")}
                        >
                            <img class="header_container_top_info_address_image" alt="address" src={images[1]} />
                            <p>110 Broad Ave. Suite 1<br />Palisades Park NJ 07650</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header;