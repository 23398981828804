import './App.scss';
import Header from './components/header';
import Hero from './components/hero';
import Carousel from './components/carousel';
import Staff from './components/staff';
import Services from './components/services';
import Footer from './components/footer';

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Carousel />
      <Staff />
      <Services />
      <Footer />
    </div>
  );
}

export default App;
