import React from 'react';
import './services.scss';
import { useState } from 'react';

const Services = () => {
  const [timesIndex, setTimesIndex] = useState(0)
  const [showButton, setShowButton] = useState(true)

  const times = [
    {
      service: "Select a service",
      time: "Approx. time"
    },
    {
      service: "Men's Cut",
      time: "20 mins"
    },
    {
      service: "Women's Cut",
      time: "20 mins"
    },
    {
      service: "Men's Perm",
      time: "40 mins"
    },
    {
      service: "Women's Perm",
      time: "60 mins"
    },
    {
      service: "Women's Color",
      time: "30 mins"
    },
    {
      service: "Women's Heat Perm (Digital & Setting)",
      time: "60 mins"
    },
    {
      service: "Japanese Straight",
      time: "60 mins"
    },
    {
      service: "Keratin",
      time: "60 mins"
    },
    {
      service: "Hair Coating",
      time: "40 mins"
    },
    {
      service: "Men's Color & Cut",
      time: "30 mins"
    },
    {
      service: "Regular Color",
      time: "20 mins"
    },
    {
      service: "Wakan Color",
      time: "30 mins"
    },
    {
      service: "Highlights",
      time: "60 mins"
    },
    {
      service: "Color Removal",
      time: "60 mins"
    },
    {
      service: "Wash & Blow",
      time: "30 mins"
    },
    {
      service: "Gel Manicure",
      time: "30 mins"
    },
    {
      service: "Gel Pedicure",
      time: "30 mins"
    },
    {
      service: "UV Set",
      time: "2 hours"
    },
    {
      service: "Fill In",
      time: "1 hour 30 mins"
    },
    {
      service: "Korean Gel Tip Set",
      time: "1 hour 30 mins"
    },
    {
      service: "Manicure / Pedicure",
      time: "40 mins"
    }
  ]

  return (
    <section class="services" id="services">
      <div class="services_container">

        <div class="services_container_title">
          SERVICES
        </div>

        <div class="services_container_tooltip">
          Select a service to display how long it takes:
        </div>

        <div class="services_container_dropdown">
          <button 
            class={showButton ? "services_container_dropdown_button" : "services_container_dropdown_button hidden"}
            onClick={() => setShowButton(false)}
          >
            {times[timesIndex].service} ▾
          </button>

          <div class={showButton ? "services_container_dropdown_list hidden" : "services_container_dropdown_list"}>
            {times.map((_,timesIndex) => {
              return (
                <p 
                  key={timesIndex}
                  class="services_container_dropdown_list_option"
                  onClick={() => {
                    setTimesIndex(timesIndex)
                    setShowButton(true)
                  }}
                >
                  {times[timesIndex].service}
                </p>
              )
          })}
          </div>
        </div>

        <div class="services_container_time">
          <div class="services_container_time_text">{times[timesIndex].time}</div>
        </div>
      </div>
    </section>
  )
}

export default Services