import React from "react";
import Slider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./carousel.scss";
import img1 from "../assets/carousel1.png";
import img2 from "../assets/carousel2.png";
import img3 from "../assets/carousel3.png";
import img4 from "../assets/carousel4.png";
import img5 from "../assets/carousel5.png";
import img6 from "../assets/carousel6.png";
// import insta from "../assets/insta.png";

const Carousel = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      centerMode: false,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      centerMode: false,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      centerMode: true,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      centerMode: true,
    },
  };

  const images = [img1, img2, img3, img4, img5, img6];

  // const openInNewTab = (url) => {
  //   window.open(url, "_blank", "noreferrer");
  // };

  return (
    <section class="carousel" id="carousel">
      <div class="carousel_container">
        <div class="carousel_container_title">
          <h1 class="carousel_container_title_text">OUR WORKS</h1>
        </div>
        <Slider
          showDots={true}
          infinite={true}
          responsive={responsive}
          containerClass="carousel_container_slider"
          dotListClass="carousel_container_slider_dots"
          autoPlay={true}
          autoPlaySpeed={4000}
        >
          {images.map((item, index) => (
            <div class="carousel_container_slider_slide" key={index}>
              <img
                class="carousel_container_slider_slide_image"
                src={item}
                alt={`Slide ${index}`}
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Carousel;
